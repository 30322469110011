import React, { FunctionComponent } from "react";
import {
  StyleSheet,
  Modal,
  ModalProps,
  TouchableHighlight,
  Platform,
  View,
  ViewStyle,
  StyleProp,
} from "react-native";
import { Portal } from "react-portal";

import { Margins, Radius } from "../../constant";

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    borderWidth: 0,
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#00000050",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    padding: Margins.regular,
    // @ts-ignore: position fixed not recognized
    maxHeight: "100vh",
  },
  content: {
    backgroundColor: "white",
    borderRadius: Radius.regular,
    overflow: "hidden",
  },
});

export interface Props extends ModalProps {
  contentStyle?: StyleProp<ViewStyle>;
}
const Alert: FunctionComponent<Props> = (props) => {
  const { children, style, contentStyle, ...otherProps } = props;
  const content = (
    <Modal
      transparent
      animationType="fade"
      {...otherProps}
      style={[styles.container, style]}
    >
      <TouchableHighlight
        underlayColor="#00000030"
        style={styles.backdrop}
        onPress={props.onRequestClose}
      >
        {/*Prevent alert is closed when pressed inside */}
        <TouchableHighlight>
          <View style={styles.modal}>
            <View style={[styles.content, contentStyle]}>{children}</View>
          </View>
        </TouchableHighlight>
      </TouchableHighlight>
    </Modal>
  );
  return Platform.OS === "web" ? <Portal>{content}</Portal> : content;
};

export default Alert;
