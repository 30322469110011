import { observable } from "mobx";

import GeneralStore from "../general/store";

type Handler = (() => void) | undefined;

class HistoryStore extends GeneralStore {
  private _back = observable.box();

  get canGoBack() {
    return !!this._back.get();
  }

  get back() {
    return this._back.get();
  }

  set back(handler: Handler) {
    this._back.set(handler);
  }
}

export default HistoryStore;
