import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  AuthSignIn,
  AuthEmail,
  AuthEmailSent,
  AuthEmailConfirm,
} from "../screens";

export type AuthStackParamList = {
  AuthSignIn: {
    next: string;
    spotId?: string;
    embed?: string;
  };
  AuthEmail: {
    next: string;
    spotId?: string;
    embed?: string;
  };
  AuthEmailSent: {
    next: string;
    spotId?: string;
    embed?: string;
  };
  AuthEmailConfirm: {
    next: string;
    spotId?: string;
    embed?: string;
  };
};

const Stack = createNativeStackNavigator<AuthStackParamList>();

const Auth = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="AuthSignIn"
        component={AuthSignIn}
        options={{ title: `My Way | ${t("Sign in")}` }}
      />
      <Stack.Screen
        name="AuthEmail"
        component={AuthEmail}
        options={{ title: `My Way | ${t("Email sign in")}` }}
      />
      <Stack.Screen
        name="AuthEmailSent"
        component={AuthEmailSent}
        options={{ title: `My Way | ${t("Email sent")}` }}
      />
      <Stack.Screen
        name="AuthEmailConfirm"
        component={AuthEmailConfirm}
        options={{ title: `My Way | ${t("Confirm sign in")}` }}
      />
    </Stack.Navigator>
  );
};

export default Auth;
