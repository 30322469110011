import {
  LucideArrowDownRight,
  LucideArrowUpRight,
  LogIn,
  LogOut,
  Forward,
  MinusCircleIcon,
  PlusCircleIcon,
  CarFront,
  Star,
  User,
  ArrowDown,
  ArrowRight,
  ArrowUp,
  ArrowLeft,
  CalendarDays,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clock,
  Mail,
  XCircle,
  MapPin,
  DoorOpen,
  QrCode,
  Headset,
  Download,
} from "lucide-react-native";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Path, Svg, G } from "react-native-svg";

import { Colors, Colors as ConstantColors } from "../../constant";
const IconSizes = {
  micro: 9,
  tiny: 16,
  small: 20,
  regular: 26,
  large: 32,
  huge: 64,
};
export type Size = keyof typeof IconSizes;
export const Sizes = Object.keys(IconSizes) as Size[];
export type Color = keyof typeof ConstantColors;

interface SvgProps {
  color: string;
  size: Size | number;
}

const Google = ({ color, size }: SvgProps) => {
  const sizeN = typeof size === "number" ? size : IconSizes[size];

  return (
    <Svg
      viewBox="0 0 210 210"
      width={sizeN - 4}
      height={sizeN - 4}
      style={{ marginRight: 12 }}
    >
      <Path
        fill={Colors.forest}
        d="M0 105C0 47.103 47.103 0 105 0c23.383 0 45.515 7.523 64.004 21.756l-24.4 31.696C133.172 44.652 119.477 40 105 40c-35.841 0-65 29.159-65 65s29.159 65 65 65c28.867 0 53.398-18.913 61.852-45H105V85h105v20c0 57.897-47.103 105-105 105S0 162.897 0 105z"
      />
    </Svg>
  );
};

const Facebook = ({ color, size }: SvgProps) => {
  const sizeN = typeof size === "number" ? size : IconSizes[size];

  return (
    <Svg
      width={sizeN}
      height={sizeN}
      viewBox="0 0 24 24"
      style={{ marginRight: 10 }}
    >
      <Path
        fill={Colors.forest}
        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
      />
    </Svg>
  );
};

const Apple = ({ color, size }: SvgProps) => {
  const sizeN = typeof size === "number" ? size : IconSizes[size];

  return (
    <Svg
      fillRule="evenodd"
      clipRule="evenodd"
      width={sizeN}
      height={sizeN}
      viewBox="0 0 640 640"
      style={{ marginRight: 12 }}
    >
      <Path
        fill={Colors.forest}
        d="M494.782 340.02c-.803-81.025 66.084-119.907 69.072-121.832-37.595-54.993-96.167-62.552-117.037-63.402-49.843-5.032-97.242 29.362-122.565 29.362-25.253 0-64.277-28.607-105.604-27.85-54.32.803-104.4 31.594-132.403 80.245C29.81 334.457 71.81 479.58 126.816 558.976c26.87 38.882 58.914 82.56 100.997 81 40.512-1.594 55.843-26.244 104.848-26.244 48.993 0 62.753 26.245 105.64 25.406 43.606-.803 71.232-39.638 97.925-78.65 30.887-45.12 43.548-88.75 44.316-90.994-.969-.437-85.029-32.634-85.879-129.439l.118-.035zM414.23 102.178C436.553 75.095 451.636 37.5 447.514-.024c-32.162 1.311-71.163 21.437-94.253 48.485-20.729 24.012-38.836 62.28-33.993 99.036 35.918 2.8 72.591-18.248 94.926-45.272l.036-.047z"
      />
    </Svg>
  );
};

const MyWayLogo = ({ color, size }: SvgProps) => {
  const sizeN = typeof size === "number" ? size : IconSizes[size];

  return (
    <Svg viewBox="0 0 354.06668 496.06668" width={sizeN} height={sizeN}>
      <G fill={Colors.forest}>
        <Path d="M0 242.04667635h47.27453215V.0026824H0v242.04399395M47.28959882.0026824l82.4582646 242.04399395h47.27479882L94.56399764.0026824H47.28959882M177.0413289 242.04667635h47.2746655V.0026824h-47.2746655v242.04399395M271.60532654.0026824H224.3319944l82.45733127 242.04399395h47.27466549L271.60532655.0026824M0 254.02267605h47.27453215V496.06667H0V254.02267605M47.28959882 496.06667l82.4582646-242.04399395h47.27479882L94.56399764 496.06667H47.28959882M177.0413289 254.02267605h47.2746655V496.06667h-47.2746655V254.02267605M271.60532654 496.06667H224.3319944l82.45733127-242.04399395h47.27466549L271.60532655 496.06667" />
      </G>
    </Svg>
  );
};

export const icons = {
  // Custom icons
  arrowIn: LucideArrowDownRight,
  arrowOut: LucideArrowUpRight,
  apple: Apple,
  google: Google,
  facebook: Facebook,
  myWay: MyWayLogo,
  doorOpen: DoorOpen,
  end: XCircle,
  logIn: LogIn,
  logOut: LogOut,
  share: Forward,
  support: Headset,
  minus: MinusCircleIcon,
  plus: PlusCircleIcon,
  clock: Clock,
  vehicle: CarFront,
  star: Star,
  arrowdown: ArrowDown,
  arrowright: ArrowRight,
  arrowup: ArrowUp,
  arrowleft: ArrowLeft,
  right: ChevronRight,
  left: ChevronLeft,
  up: ChevronDown,
  down: ChevronDown,
  calendar: CalendarDays,
  checkCircle: CheckCircle,
  error: XCircle,
  mail: Mail,
  pin: MapPin,
  qrCode: QrCode,
  user: User,
  download: Download,
};

export type IconName = keyof typeof icons;

type IconProps = {
  name: IconName;
  color?: string;
  fill?: string;
  size?: Size | number;
  strokeWidth?: number;
  style?: StyleProp<ViewStyle>;
};

const Icon = ({
  name,
  color = "currentColor",
  size = "regular",
  strokeWidth = 2.5,
  fill = undefined,
  style,
}: IconProps) => {
  if (!icons.hasOwnProperty(name)) {
    console.warn("LucideIcon: icon not found", name);
    return null;
  }
  const IconComponent = icons[name];

  return (
    <IconComponent
      fill={fill ?? "rgba(0,0,0,0)"}
      strokeWidth={strokeWidth}
      color={color}
      size={typeof size === "number" ? size : IconSizes[size]}
      style={style}
    />
  );
};

export default Icon;
