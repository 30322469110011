import {
  Outfit_300Light,
  Outfit_400Regular,
  Outfit_500Medium,
  Outfit_600SemiBold,
  Outfit_700Bold,
} from "@expo-google-fonts/outfit";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import { observer } from "mobx-react";
import React from "react";
import { MenuProvider } from "react-native-popup-menu";

import Router from "./src/routes";
import { useStore } from "./src/stores";

const App = () => {
  const { auth } = useStore();
  const [fontsLoaded] = useFonts({
    "Outfit-Light": Outfit_300Light,
    "Outfit-Regular": Outfit_400Regular,
    "Outfit-Medium": Outfit_500Medium,
    "Outfit-SemiBold": Outfit_600SemiBold,
    "Outfit-Bold": Outfit_700Bold,
  });

  if (!fontsLoaded || !auth.isLoaded) {
    return <AppLoading />;
  }

  return (
    <MenuProvider >
      <Router />
    </MenuProvider>
  );
};

export default observer(App);
