import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page } from "../../components";
import { AuthStackParamList } from "../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../stores";
import { Button, Colors, Margins, Radius, Text, Title } from "../../storybook";

const styles = StyleSheet.create({
  content: {
    paddingVertical: Margins.large,
  },
  title: {
    color: Colors.forest,
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
    borderColor: Colors.forest,
    borderWidth: 2,
    borderRadius: 30,
  },
  hidden: {
    opacity: 0,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

type SignInNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "AuthSignIn"
>;
type SignInRouteProp = RouteProp<AuthStackParamList, "AuthSignIn">;
type Provider = "facebook" | "google" | "apple" | "email";

interface Props {
  navigation: SignInNavigationProp;
  route: SignInRouteProp;
}
const SignIn = (props: Props) => {
  const { navigation, route } = props;
  const { next, spotId, embed } = route.params || {};
  const store = useStore();
  const { auth } = store;
  const [provider, setProvider] = useState<Provider | undefined>();
  const [error, setError] = useState<
    { [provider: string]: string } | undefined
  >();
  const { t } = useTranslation();
  const linkTo = useLinkTo();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setProvider(undefined);
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    }
  }, [error]);

  const signIn = (value: Provider) => async () => {
    setProvider(value);
    try {
      if (value === "email") {
        if (Platform.OS === "web") {
          let query = "";
          if (route?.params) {
            query = Object.keys(route?.params)
              .map(
                (key) =>
                  `${key}=${encodeURIComponent((route.params as any)[key])}`
              )
              .join("&");
            if (embed) {
              window
                .open(`/email?${query.replace("embed=true", "")}`, "_blank")
                ?.focus();
            } else {
              window.location.href = `/email?${query}`;
            }
          } else {
            window.location.href = `/email`;
          }
        } else {
          navigation.navigate("AuthEmail", route.params);
        }
        return;
      }
      switch (value) {
        case "facebook":
          await auth.signInWithFacebook();
          break;
        case "google":
          await auth.signInWithGoogle();
          break;
        case "apple":
          await auth.signInWithApple();
          break;
      }
      if (Platform.OS === "web") {
        window.location.href = next || "/";
      } else {
        linkTo(next || "/");
      }
    } catch (error) {
      console.error("Error: ", error);
      setError({ [provider || ""]: t("Sign in failed") });
      setProvider(undefined);
    }
  };

  return (
    <Page embed={!!embed} spot={typeof spot === "string" ? undefined : spot}>
      <View style={styles.content}>
        <Title level={1} style={styles.title}>
          <Trans>Join the My Way community</Trans>
        </Title>
        <Text style={{ color: Colors.forest }}>
          <Trans>
            My Way is here to help you with booking and renting out parking
            places
          </Trans>
        </Text>
        <Button
          style={[
            styles.button,
            provider && provider !== "facebook" && styles.hidden,
          ]}
          key="facebook"
          left="facebook"
          title={error?.facebook || t("Facebook")}
          right="arrowright"
          onPress={signIn("facebook")}
          color={error?.facebook ? "red" : "transparent"}
          loading={provider === "facebook"}
        />
        <Button
          style={[
            styles.button,
            provider && provider !== "apple" && styles.hidden,
          ]}
          key="apple"
          left="apple"
          title={error?.apple || t("Apple")}
          color={error?.apple ? "red" : "transparent"}
          right="arrowright"
          onPress={signIn("apple")}
          loading={provider === "apple"}
        />
        <Button
          style={[
            styles.button,
            provider && provider !== "google" && styles.hidden,
          ]}
          key="google"
          left="google"
          title={error?.google || t("Google")}
          color="transparent"
          right="arrowright"
          onPress={signIn("google")}
          loading={provider === "google"}
        />
        <Button
          style={[
            styles.button,
            provider && provider !== "email" && styles.hidden,
          ]}
          key="mail"
          left="mail"
          title={error?.email || t("Email")}
          color={error?.email ? "red" : "transparent"}
          right="arrowright"
          onPress={signIn("email")}
          loading={provider === "email"}
        />
      </View>
    </Page>
  );
};

export default withAuthentication(observer(SignIn));
