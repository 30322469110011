import { useNavigation } from "@react-navigation/core";
import { observer } from "mobx-react";
import React, { ReactNode, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View, Linking } from "react-native";

import { reportError, translatedError } from "../../../helpers";
import { Booking } from "../../../stores";
import {
  Button,
  LicensePlate,
  ListItem,
  Margins,
  Text,
  Title,
} from "../../../storybook";
import { CountryCode } from "../../../storybook/component/country/list";

const styles = StyleSheet.create({
  containerMain: {
    flex: 1,
  },
  button: {
    marginVertical: Margins.small,
  },
  container: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    flexDirection: "row",
    marginVertical: Margins.small,
  },
  col: {
    flex: 1,
  },
  item: {
    flex: 1,
  },
});

interface Props {
  checkout?: boolean;
  booking: Booking;
}
const BookingCompleted = (props: Props) => {
  const { checkout, booking } = props;
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const [error, setError] = useState<{ exit?: ReactNode; access?: ReactNode }>(
    {}
  );
  const [loading, setLoading] = useState<{ exit?: boolean; access?: boolean }>(
    {}
  );
  useEffect(() => {
    if (error.exit || error.access) {
      setTimeout(() => {
        setError({});
      }, 3000);
    }
  }, [error]);

  // write a useEffect that will auto redirect to a page after 10 seconds
  useEffect(() => {
    if (!checkout) return;
    const timer = setTimeout(() => {
      // @ts-ignore
      navigate("PublicEnd", { spotId: booking.spot?.id });
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  const wait = (milliseconds: number) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  const openExit = async () => {
    try {
      setLoading({ exit: true });
      await booking.openGate("exit");
      await wait(10000);
      setLoading({});
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      reportError(err as Error, {
        name: "public/checkout/pay/completed",
        type: "web",
        args: {
          bookingId: booking.id,
        },
      });
      setError({ exit: translatedError(message) });
      setLoading({});
    }
  };

  // const openAccess = async () => {
  //   try {
  //     setLoading({ access: true });
  //     await booking.openGate("access");
  //     setLoading({});
  //   } catch (err) {
  //     const { message } = err as Error;
  //     console.error("Error: ", message);
  //     reportError(err as Error, {
  //       name: "public/checkout/pay/completed",
  //       type: "web",
  //       args: {
  //         bookingId: booking.id,
  //       },
  //     });
  //     setError({ access: translatedError(message) });
  //     setLoading({});
  //   }
  // };

  const contactSupport = async () => {
    await Linking.openURL(`tel:0031207762939`);
  };

  return (
    <View style={styles.containerMain}>
      <Text style={styles.row}>
        {checkout && (
          <Trans>
            Thank you for your booking. You can now leave the parking.
          </Trans>
        )}
        {!checkout && (
          <Trans>
            Thank you for your booking. You can now leave the parking. The exit
            will open automatically when your license plate is scanned.
          </Trans>
        )}
      </Text>
      {checkout && (
        <Button
          style={styles.button}
          title={
            error.access || booking.exitTimes === 1
              ? t("Retry barrier")
              : booking.exitTimes > 1
              ? t("Contact support")
              : t("Exit parking")
          }
          color={
            booking.exitTimes === 1
              ? "orange"
              : booking.exitTimes > 1
              ? "orange"
              : "red"
          }
          left={booking.exitTimes > 1 ? "support" : "logOut"}
          onPress={booking.exitTimes > 1 ? contactSupport : openExit}
          loading={loading.exit}
          disabled={loading.exit}
        />
      )}

      <Title level={3} style={styles.title}>
        <Trans>The gate will open</Trans>
      </Title>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem title={t("Time to leave")} description={booking.exitAt} />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Exit before")}
            description={`${booking.endAtPlus20Mins}`}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("License plate")}
            description={
              <LicensePlate
                country={booking.vehicle?.country as CountryCode}
                code={booking.vehicle?.licensePlate}
              />
            }
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Found vehicle")}
            description={booking.vehicle?.description || t("Unknown vehicle")}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("Start time")}
            description={`${booking.displayStartDate}\n${booking.displayStartTime}`}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("End time")}
            description={`${booking.displayEndDate}\n${booking.displayEndTime}`}
          />
        </View>
      </View>
      <View style={[styles.row]}>
        <View style={styles.col}>
          <Button
            title={t("Back")}
            left="arrowleft"
            onPress={() =>
              // @ts-ignore
              navigate("PublicEnd", { spotId: booking.spot?.id })
            }
          />
        </View>
      </View>
    </View>
  );
};

export default observer(BookingCompleted);
