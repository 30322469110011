import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page, SpotClosing, VehicleForm } from "../../components";
import { reportError, translatedError } from "../../helpers";
import { PublicStackParamList } from "../../routes/public";
import { Spot, useStore, withAnonymous, Bookings } from "../../stores";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import {
  Button,
  ListItem,
  Margins,
  Radius,
  Title,
  Vehicle,
} from "../../storybook";

const styles = StyleSheet.create({
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  content: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
  vehicle: {
    marginBottom: Margins.large,
  },
  buttons: {
    width: "100%",
  },
});

interface Data {
  vehicle?: Vehicle;
}

type EndNavigationProp = StackNavigationProp<PublicStackParamList, "PublicEnd">;
type EndRouteProp = RouteProp<PublicStackParamList, "PublicEnd">;

interface Props {
  navigation: EndNavigationProp;
  route: EndRouteProp;
}
const End = (props: Props) => {
  const { route, navigation } = props;
  const { spotId, checkout } = route.params || {};
  const store = useStore();
  const signedIn = store.auth.isSignedIn;
  const spot = useMemo(
    () => new Spot(`spots/${spotId}`, {}, store),
    [spotId, signedIn]
  );

  const [value, setValue] = useState<Data>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    }
  }, [error]);

  const { vehicle } = value;
  const availability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
            parkWhenClosed: false,
          })
        : undefined,
    [spot.isLoaded]
  );

  const updateVehicle = (value: Vehicle) => {
    setValue({
      vehicle: value,
    });
  };

  const confirm = async () => {
    setLoading(true);
    let bookingId;
    try {
      if (!vehicle || !vehicle.code || !vehicle.country)
        throw new Error("Vehicle not found");
      const licenseRef = sanitizeLicense(vehicle.code);
      const bookings = new Bookings(
        { spotId, "vehicle.licenseRef": licenseRef },
        store
      );
      await bookings.fetch();

      const newBooking = bookings.hasDocs ? bookings.docs[0] : undefined;
      if (!newBooking) throw new Error("No booking found for this vehicle");
      bookingId = newBooking.id || "";
      if (Platform.OS === "web") {
        window.location.href = checkout
          ? `/public/confirm/checkout/${bookingId}`
          : `/public/confirm/${bookingId}`;
      } else {
        navigation.navigate("PublicConfirm", { bookingId, checkout });
      }
    } catch (error) {
      const { message } = error as Error;
      console.error("Error: ", error);
      reportError(error as Error, {
        name: "public/end",
        type: "web",
        args: {
          bookingId,
        },
      });
      setError(translatedError(message));
    }
    setLoading(false);
  };

  const licensePlateValid = (vehicle?.code?.length || 0) > 2;

  return (
    <Page spot={spot}>
      <View style={styles.content}>
        <Title level={2} style={styles.title}>
          <Trans>Lets find your booking.</Trans>
        </Title>
        <VehicleForm
          value={vehicle}
          onChange={updateVehicle}
          // @ts-ignore
          style={[styles.row, styles.vehicle]}
        />
        <SpotClosing availability={availability} />
        {vehicle?.vehicle && (
          <ListItem
            style={styles.row}
            title={t("Found vehicle")}
            description={`${vehicle?.vehicle}`}
          />
        )}
        <Button
          title={error || t("Get booking")}
          color={error ? "red" : undefined}
          style={styles.row}
          disabled={!licensePlateValid || loading}
          onPress={confirm}
          loading={loading}
        />
      </View>
    </Page>
  );
};

export default withAnonymous(observer(End));
