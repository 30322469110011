import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import { ActivityIndicator, StyleSheet } from "react-native";

import { VehicleSearch } from "../../stores";
import {
  LicensePlateInput,
  Margins,
  Vehicle,
  Colors,
  LicensePlateInputProps,
} from "../../storybook";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
  buttons: {
    width: "100%",
  },
});
export interface Data {
  vehicle?: Vehicle;
}

interface Props extends LicensePlateInputProps {
  value?: Vehicle;
  onChange?: (value: Vehicle) => void;
  lastScanned?: string;
}

const VehicleForm = (props: Props) => {
  const { value, onChange, lastScanned, style } = props;
  const vehicleSearch = useRef(
    new VehicleSearch(value?.code, value?.country)
  ).current;

  const { result, error, inProgress } = vehicleSearch;

  useEffect(() => {
    const newVehicle = value || ({} as Vehicle);
    if (!result && error !== "Vehicle not found") newVehicle.vehicle = "";
    else {
      const vehicle: any = [];
      const { brand, model } = result || {};
      if (brand) vehicle.push(brand);
      if (model) vehicle.push(model);
      newVehicle.code = vehicleSearch.formattedLicensePlate;
      newVehicle.vehicle = vehicle.join(" ");
    }
    onChange && onChange(newVehicle);
  }, [result, error]);

  useEffect(() => {
    vehicleSearch.country = value?.country || "NL";
    vehicleSearch.licensePlate = value?.code || "";
  }, [value]);

  const licensePlateValid = (value?.code?.length || 0) > 2;
  const licensePlate = {
    ...value,
    code: value?.code || "",
    country: value?.country || "NL",
  };

  return (
    <LicensePlateInput
      onChange={onChange}
      value={lastScanned ? { code: lastScanned, country: "NL" } : licensePlate}
      state={error ? "error" : licensePlateValid ? "success" : "default"}
      style={[styles.row, style]}
      addon={
        inProgress
          ? { after: <ActivityIndicator color={Colors.lightGreen} /> }
          : undefined
      }
    />
  );
};

export default observer(VehicleForm);
