import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { CountryCode } from "@storybook/component/country/list";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import QRCodeScreen from "./qrCodeScreen";
import { Booking } from "../../../stores";
import {
  Button,
  Margins,
  Text,
  ListItem,
  LicensePlate,
} from "../../../storybook";

interface Props {
  booking: Booking;
  onRetry?: () => void;
  onShare?: () => Promise<void>;
}
type HomeNavigationProp = StackNavigationProp<any, "Home">;

type Action = "access" | "exit" | "entrance" | "end";

const BookingShared = (props: Props) => {
  const [error, setError] = useState<{
    share?: string;
    access?: string;
    exit?: string;
    entrance?: string;
    end?: string;
  }>({});
  const [loading, setLoading] = useState({
    entrance: false,
    access: false,
    exit: false,
    end: false,
  });

  const [disabled, setDisabled] = useState({
    entrance: false,
    access: false,
    exit: false,
    end: false,
  });
  const [showQRCode, setShowQRCode] = useState(false);  const navigation: HomeNavigationProp = useNavigation();

  const { booking } = props;
  if (!booking.hasData) {
    navigation.navigate("Home");
  }
  const { vehicle } = booking;
  const licensePlate = vehicle?.licensePlate || "";
  const { t } = useTranslation();

  const showError = (type: Action, message: string) => {
    setError({ [type]: message });
    setTimeout(() => {
      setError({});
    }, 3000);
  };

  const unDisable = (type: Action) => {
    setTimeout(() => {
      setDisabled({ ...disabled, [type]: false });
    }, 6000);
  };

  const openAccess = async () => {
    if (booking.gateQRCode) {
      setShowQRCode(true);
    } else {
      setLoading({ ...loading, access: true });
      setDisabled({ ...disabled, access: true });
      try {
        await booking.openGate("access");
      } catch (err) {
        const { message } = err as Error;
        console.error("Error: ", message);
        showError("access", message);
      } finally {
        unDisable("access");
        setLoading({ ...loading, access: false });
      }
    }
  };

  const openExit = async () => {
    setLoading({ ...loading, exit: true });
    setDisabled({ ...disabled, exit: true });
    try {
      await booking.openGate("exit");
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      showError("exit", message);
    } finally {
      unDisable("exit");
      setLoading({ ...loading, exit: false });
    }
  };

  const openEntrance = async () => {
    setLoading({ ...loading, entrance: true });
    setDisabled({ ...disabled, entrance: true });
    try {
      await booking.openGate("entrance");
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      showError("entrance", message);
    } finally {
      unDisable("entrance");
      setLoading({ ...loading, entrance: false });
    }
  };

  const endBooking = async () => {
    setLoading({ ...loading, end: true });
    setDisabled({ ...loading, end: true });
    try {
      await booking.endBooking();
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      showError("end", message);
    } finally {
      unDisable("end");
      setLoading({ ...loading, end: false });
    }
  };

  const bookingEnded =
    booking.status === "ended" || booking.status === "ended_will_depart";

  const bookingStarted =
    booking.status === "started" ||
    booking.status === "started_parked" ||
    booking.status === "started_will_arrive" ||
    booking.status === "started_arrived" ||
    booking.status === "started_will_depart";

  const notParked =
    booking.status === "started_not_parked" ||
    booking.status === "started_will_arrive" ||
    booking.status === "started_arrived" ||
    booking.status === "started_will_depart" ||
    booking.status === "started_departed";

  return (
    <View style={styles.container}>
      <View>
        {!bookingEnded && (
          <Button
            title={error.entrance || t("Enter parking")}
            centre="logIn"
            color={
              error.entrance
                ? "red"
                : !booking.canOpenEntrance
                ? "grey"
                : "green"
            }
            style={styles.marginBottom}
            onPress={!error.entrance ? openEntrance : undefined}
            loading={loading.entrance}
            disabled={!booking.canOpenEntrance || disabled.entrance}
          />
        )}
        {!bookingEnded && (
          <Button
            title={error.access || t("Access parking")}
            centre={booking.gateQRCode ? "qrCode" : "doorOpen"}
            color={
              error.access ? "red" : !booking.canOpenAccess ? "grey" : undefined
            }
            style={styles.marginBottom}
            onPress={!error.access ? openAccess : undefined}
            loading={loading.access}
            disabled={
              !booking.canOpenAccess || disabled.access || !bookingStarted
            }
          />
        )}
      </View>
      {!bookingEnded && booking.canOpenExit ? (
        <Button
          title={error.exit || t("Exit parking")}
          centre="logOut"
          color="red"
          style={styles.marginBottom}
          onPress={!error.exit ? openExit : undefined}
          loading={loading.exit}
          disabled={disabled.exit}
        />
      ) : (
        !bookingEnded &&
        notParked && (
          <Button
            title={error.end || t("End booking")}
            centre="end"
            color="red"
            style={styles.marginBottom}
            onPress={!error.end ? endBooking : undefined}
            loading={loading.end}
            disabled={disabled.end}
          />
        )
      )}
      <View style={styles.row}>
        <View style={styles.column}>
          <ListItem
            title={t("Arrival")}
            description={
              <>
                <Text>{booking.displayStartTime}</Text>
                <Text>{booking.displayStartDate}</Text>
              </>
            }
          />
        </View>
        <View style={styles.column}>
          <ListItem
            title={t("Departure")}
            description={
              <>
                <Text>{booking.displayEndTime}</Text>
                <Text>{booking.displayEndDate}</Text>
              </>
            }
          />
        </View>
      </View>
      <ListItem
        style={styles.marginBottom}
        title={t("Duration")}
        description={<Text>{booking.duration}</Text>}
      />
      {booking.spot?.address && (
        <ListItem
          style={styles.marginBottom}
          title={t("Location")}
          description={
            <Text>
              {booking.spot?.address?.street},{" "}
              {booking.spot?.address?.postalCode}, {booking.spot?.address?.city}
            </Text>
          }
        />
      )}
      <ListItem
        style={styles.marginBottom}
        title={t("Vehicle")}
        description={
          <LicensePlate
            code={licensePlate}
            country={(vehicle?.country as CountryCode) ?? undefined}
          />
        }
      />
      {booking.gateQRCode && (
        <QRCodeScreen
          qrCode={booking.gateQRCode}
          visible={showQRCode}
          onRequestClose={() => {
            setShowQRCode(false);
          }}
        />
      )}
    </View>
  );
};

export default observer(BookingShared);

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
  },
  row: { marginVertical: Margins.small, flexDirection: "row" },
  marginBottom: { marginBottom: Margins.regular },
  column: { flex: 1 },
});
