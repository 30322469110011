import React from "react";
import { TextInput, TextInputProps } from "react-native";

import InputView, { Props as InputProps } from "./input";
import { Colors, Radius } from "../../constant";

export interface Props
  extends Omit<TextInputProps, "style">,
    Omit<InputProps, "renderContent"> {}

const Input = (props: Props) => {
  const {
    label,
    borderColor,
    state,
    addon,
    style,
    contentStyle,
    disabled,
    ...otherProps
  } = props;
  return (
    <InputView
      {...{ borderColor, state, addon, style }}
      contentStyle={[
        contentStyle,
        { backgroundColor: Colors.moonMist, borderRadius: Radius.small },
      ]}
      renderContent={(contentProps) => {
        const { style, textStyle } = contentProps;
        return (
          <TextInput
            style={[style, textStyle]}
            editable={!disabled}
            placeholder={label}
            placeholderTextColor={Colors.grey}
            {...otherProps}
          />
        );
      }}
    />
  );
};

export default Input;
