import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useMemo, useEffect } from "react";
import { Trans } from "react-i18next";
import { StyleSheet, View, Platform } from "react-native";

import { Page, BookingDirectPay, BookingDirectStatus } from "../../components";
import { PublicStackParamList } from "../../routes/public";
import { Booking, useStore, withAnonymous } from "../../stores";
import { Margins, Radius, Title } from "../../storybook";

const styles = StyleSheet.create({
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
  content: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
});

type PayNavigationProp = StackNavigationProp<PublicStackParamList, "PublicPay">;
type PayRouteProp = RouteProp<PublicStackParamList, "PublicPay">;

interface Props {
  navigation: PayNavigationProp;
  route: PayRouteProp;
}
const Scan = (props: Props) => {
  const { route, navigation } = props;
  const { bookingId } = route.params || {};
  const store = useStore();
  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );

  const spot = useMemo(() => booking.spot, [booking.isLoaded]);
  const spotId = spot?.id;
  useEffect(() => {
    if (spotId) {
      store.history.back = () => {
        if (Platform.OS === "web") {
          window.location.href = `/public/end/${spotId}`;
        } else {
          navigation.navigate("PublicEnd", { spotId });
        }
      };
    }
  }, [spotId]);

  const renderQR = () => (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Pay with your phone</Trans>
      </Title>
      <BookingDirectPay booking={booking} />
    </>
  );

  if (!booking.isLoaded || !spot?.isLoaded) return null;

  const status = booking.isFailed
    ? "error"
    : booking.isLeaving
    ? "success"
    : "pending";

  return (
    <Page spot={spot}>
      <View style={styles.content}>
        {status === "pending" ? (
          renderQR()
        ) : (
          <BookingDirectStatus status={status} booking={booking} />
        )}
      </View>
    </Page>
  );
};

export default withAnonymous(observer(Scan));
