import React, { ReactNode } from "react";
import {
  View,
  StyleSheet,
  Platform,
  TextStyle,
  ViewStyle,
  StyleProp,
} from "react-native";

import { Colors, Margins, Radius, Fonts } from "../../constant";
import IconView, { IconName, Color as IconColor } from "../icon/icon";
import { Text } from "../typography";
import { TextStyles } from "../typography/text";

const HEIGHT = 50;
const BORDER = 1;

const styles = StyleSheet.create({
  container: {
    borderRadius: Radius.small,
  },
  background: {
    width: 90,
    borderRadius: Radius.small,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    flex: 1,
    height: HEIGHT - 2 * BORDER,
  },
  inputText: {
    fontFamily: Fonts.value,
    fontSize: TextStyles[4].fontSize,
    fontWeight: "normal",
  },
  label: {
    marginLeft: Margins.regular,
  },
  before: {
    //  marginLeft: Margins.regular,
  },
  after: {
    marginRight: Margins.regular,
  },
});

interface StateStyle {
  borderColor: [string, string];
  icon?: IconName;
  iconColor?: string;
}

export const StateStyles: { [color: string]: StateStyle } = {
  default: {
    borderColor: [Colors.grey, Colors.grey],
  },
  error: {
    borderColor: [Colors.darkRed, Colors.lightRed],
    icon: "error",
    iconColor: Colors.darkRed as IconColor,
  },
  success: {
    borderColor: [Colors.darkGreen, Colors.lightGreen],
    icon: "checkCircle",
    iconColor: Colors.darkGreen as IconColor,
  },
};
export type State = keyof typeof StateStyles;
export const States = Object.keys(StateStyles) as State[];

export type Icon = IconName;

interface AddOn {
  before?: ReactNode | false;
  after?: ReactNode | false;
}

export interface ContentProps {
  style?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconColor?: string;
}

export interface Props {
  renderContent: (props: ContentProps) => ReactNode;
  label?: string;
  borderColor?: [string, string];
  state?: State;
  addon?: AddOn;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  placeholder?: string;
}
const Input = (props: Props) => {
  const { renderContent, label, state, addon, style, contentStyle } = props;
  const currentState = state || "default";
  const stateStyle = StateStyles[currentState];

  const after =
    (addon?.after && <View style={styles.after}>{addon.after}</View>) ||
    (addon?.after !== false && stateStyle.icon && (
      <IconView
        name={stateStyle.icon}
        style={styles.after}
        color={stateStyle.iconColor}
      />
    ));
  const before = addon?.before && (
    <View style={styles.before}>{addon.before}</View>
  );

  const inputStyle = {
    paddingLeft: before ? Margins.small : Margins.small,
    paddingRight: after ? Margins.small : Margins.small,
    ...(Platform.OS === "web" ? { outline: "none" } : undefined),
  };

  return (
    <View style={[styles.container, style]}>
      {label && (
        <Text level={5} style={styles.label}>
          {label}
        </Text>
      )}
      <View style={[styles.content, contentStyle]}>
        {before}
        {renderContent({
          style: [styles.input, inputStyle],
          textStyle: styles.inputText,
          iconColor: stateStyle.iconColor,
        })}
        {after}
      </View>
    </View>
  );
};

export default Input;
