import React, { useEffect, useRef, useState } from "react";
import {
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
} from "react-native";

import InputView, { Props as InputProps } from "./input";
import { Colors, Margins, Radius } from "../../constant";
import CountryList, {
  Country,
  CountryCode,
  Search as CountrySearch,
} from "../country/list";
import { Icon } from "../icon";
import { PopUp } from "../modal";
import { TextStyles } from "../typography/text";
import { LicensePlateCountry } from "../vehicle";
import { Countries } from "../vehicle/styles";

const styles = StyleSheet.create({
  country: {
    height: 50 - 2 * Margins.tiny,
    borderTopStartRadius: Radius.small,
    borderBottomStartRadius: Radius.small,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  countryText: {
    color: Colors.white,
  },
  down: {
    marginLeft: 4,
    marginRight: -4,
  },
  input: {
    height: 50 - 2 * Margins.tiny,
    marginVertical: 0,
    borderTopRightRadius: Radius.small,
    borderBottomRightRadius: Radius.small,
    fontSize: TextStyles[4].fontSize,
    maxWidth: 120,
    flexBasis: "auto",
    textAlign: "center",
    overflow: "hidden",
    borderLeftWidth: 0,
  },
  vehicle: {
    flex: 1,
    height: 50,
    justifyContent: "center",
    marginLeft: Margins.small,
  },
  picker: {
    maxHeight: 600,
    minWidth: 400,
  },
});

export interface Vehicle {
  country?: CountryCode;
  code?: string;
  vehicle?: string;
}

export interface Props
  extends Omit<
      TextInputProps,
      "style" | "value" | "defaultValue" | "onChange" | "editable"
    >,
    Omit<InputProps, "renderContent"> {
  value?: Vehicle;
  defaultValue?: Vehicle;
  onChange?: (value: Vehicle) => void;
  language?: string;
  search?: CountrySearch;
}

const InputLicensePlate = (props: Props) => {
  const {
    label,
    borderColor,
    state,
    addon,
    style,
    contentStyle,
    disabled,
    value,
    defaultValue,
    onChange,
    language,
    search,
    ...otherProps
  } = props;
  const [licensePlate, setLicensePlate] = useState<Vehicle>(
    value || defaultValue || { country: "NL" }
  );
  const [showPicker, setShowPicker] = useState(false);
  const input = useRef<TextInput>(null);
  const { country } = licensePlate;

  useEffect(() => {
    value && setLicensePlate(value);
  }, [value]);

  const updateCode = (code: string) => {
    const newLicensePlate = { ...licensePlate, code: code.toUpperCase() };
    setLicensePlate(newLicensePlate);
    onChange && onChange(newLicensePlate);
  };

  const updateCountry = (data: Country) => {
    const newLicensePlate = { ...licensePlate, country: data.id };
    setLicensePlate(newLicensePlate);
    onChange && onChange(newLicensePlate);
    setShowPicker(false);
  };

  const renderCountry = () => (
    <Pressable onPress={() => setShowPicker(true)} disabled={disabled}>
      <LicensePlateCountry country={country} style={styles.country}>
        {!disabled && (
          <Icon style={styles.down} name="down" color="white" size={14} />
        )}
      </LicensePlateCountry>
    </Pressable>
  );

  const renderPicker = () => {
    const picker = (
      <CountryList
        language={language}
        region="Europe"
        preferred={["NL", "BE", "DE"]}
        onSelect={updateCountry}
        search={search}
      />
    );
    if (Platform.OS === "web")
      return <View style={styles.picker}>{picker}</View>;
    return picker;
  };

  return (
    <>
      <InputView
        addon={{ before: renderCountry() }}
        renderContent={(contentProps) => {
          const { style, textStyle } = contentProps;
          return (
            <TextInput
              style={[
                style,
                textStyle,
                styles.input,
                Countries.default.code?.containerStyle,
                Countries.default.code?.textStyle,
                Countries.default.licensePlate?.containerStyle,
              ]}
              ref={input}
              value={licensePlate.code}
              onChangeText={updateCode}
              keyboardType={
                Platform.OS === "ios" ? "default" : "visible-password"
              } // To fix a bug with captilization in Android
              editable={!disabled}
              {...otherProps}
            />
          );
        }}
      />
      {showPicker && (
        <PopUp onRequestClose={() => setShowPicker(false)}>
          {renderPicker()}
        </PopUp>
      )}
    </>
  );
};

export default InputLicensePlate;
