import moment, { Moment } from "moment-timezone";

import { toMoment, dateWithTime } from "./date";
import { getSpotTimeZone } from "./timezone";
import { Data as SpotData } from "../spot";

export interface Options {
  startAt: Date;
  endAt?: Date;
  parkWhenClosed?: boolean;
}

export type StatusCode =
  | "day-closed" // Spot is closed all day in requested period
  | "period-closed" // Spot is closed in requested period
  | "start-closed" // Spot is closed at requested start
  | "end-closed" // Spot is closed at requested end
  | "start-end-closed" // Spot is closed at requested start and end
  | "between-closed" // Spot is closed during the requested period (and vehicles can't park when closed)
  | "open"; // Spot is open during requested period

export interface Status {
  startAt: Date;
  endAt: Date;
  open: boolean;
  code: StatusCode;
}

export interface WeekDayAvailability {
  closed?: boolean;
  startAt?: string; // E.g. "7:30"
  endAt?: string; // E.g. "18:00"
}

export type WeekDay = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
const WEEKDAYS: WeekDay[] = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const DEFAULT_WEEKDAY: WeekDayAvailability = {
  startAt: undefined,
  endAt: undefined,
  closed: false,
};

export function getWeekDays(): WeekDay[] {
  return WEEKDAYS;
}

interface PeriodAvailability {
  startAt: Moment;
  endAt: Moment;
  closed: boolean;
}

export function spotIsOpen(spotData: SpotData, when: Date): boolean {
  const date = moment(when);
  const weekdays = getWeekDays();
  const weekday = weekdays[date.isoWeekday() - 1];
  const { availability, openingTimes } = spotData;
  const timezone = getSpotTimeZone(spotData);
  const openingTime = openingTimes?.[weekday] || DEFAULT_WEEKDAY;
  const periods: PeriodAvailability[] = Object.values(availability || {})
    .map((item) => ({
      startAt: toMoment(item.startAt, timezone),
      endAt: toMoment(item.endAt, timezone),
      closed: !item.open,
    }))
    .sort((a, b) => a.startAt.valueOf() - b.startAt.valueOf());
  const period = periods.find(
    (item) =>
      moment(item.startAt).startOf("day").isSameOrBefore(date) &&
      moment(item.endAt).endOf("day").isSameOrAfter(date)
  );
  if (period) {
    // Period Availability takes precedence over opening times
    if (period.startAt && date.isBefore(period.startAt)) {
      // Before period
      return period.closed;
    } else if (period.endAt && date.isAfter(period.endAt)) {
      // After period
      return period.closed;
    } else {
      // Within period
      return !period.closed;
    }
  }

  const openingTimeStartAt =
    openingTime.startAt && dateWithTime(date, openingTime.startAt, timezone);
  const openingTimeEndAt =
    openingTime.endAt && dateWithTime(date, openingTime.endAt, timezone);
  if (openingTimeStartAt && date.isBefore(openingTimeStartAt)) {
    // Before opening
    return !!openingTime.closed;
  } else if (openingTimeEndAt && date.isAfter(openingTimeEndAt)) {
    // After opening
    return !!openingTime.closed;
  } else {
    // Within opening
    return !openingTime.closed;
  }
}

export function getAvailabilityStatus(
  spotData: SpotData,
  options: Options
): Status {
  const { availability, openingTimes } = spotData;
  const timezone = getSpotTimeZone(spotData) || "Europe/Amsterdam";
  if (!options.startAt) throw new Error("Invalid start date");
  const startAt = toMoment(options.startAt, timezone);
  const endAt = options.endAt
    ? toMoment(options.endAt, timezone)
    : toMoment(startAt, timezone).add(3, "hours");
  const parkWhenClosed =
    options.parkWhenClosed !== undefined
      ? options.parkWhenClosed
      : spotData.parkWhenClosed;
  // console.log('Status: ', startAt.toISOString(), ' - ', endAt.toISOString());
  const weekdays = getWeekDays();
  // console.log('Opening times: ', openingTimes);
  const periods: PeriodAvailability[] = Object.values(availability || {})
    .map((item) => ({
      startAt: toMoment(item.startAt, timezone),
      endAt: toMoment(item.endAt, timezone),
      closed: !item.open,
    }))
    .sort((a, b) => a.startAt.valueOf() - b.startAt.valueOf());

  const closureBetween = (start: Moment, end: Moment) => {
    const periodsBetween = periods
      .filter(
        (item) =>
          (moment(item.startAt).startOf("day").isSameOrBefore(start) &&
            moment(item.endAt).endOf("day").isSameOrAfter(start)) ||
          (moment(item.startAt).startOf("day").isSameOrBefore(end) &&
            moment(item.endAt).endOf("day").isSameOrAfter(end))
      )
      .sort((p1, p2) => p1.startAt.unix() - p2.startAt.unix());

    const closureForOpening = (
      startDate: Moment,
      endDate: Moment,
      openingStart: Moment,
      openingEnd: Moment,
      closed: boolean
    ) => {
      // console.log('  Day: ', startDate.toISOString(), ' - ', endDate.toISOString());
      // console.log('  Opening: ', openingStart.toISOString(), ' - ', openingEnd.toISOString());
      if (
        (startDate.isBefore(openingStart) && endDate.isBefore(openingStart)) ||
        (startDate.isAfter(openingEnd) && endDate.isAfter(openingEnd))
      ) {
        // console.log('   Outside');
        // Period outside opening times;
        if (!closed) return startDate;
      } else if (
        startDate.isBefore(openingStart) &&
        endDate.isAfter(openingStart)
      ) {
        // console.log('   Overlap');
        // Period overlaps opening times;
        if (closed) return openingStart;
        else return startDate;
      } else if (
        startDate.isSameOrAfter(openingStart) &&
        endDate.isSameOrBefore(openingEnd)
      ) {
        // console.log('   Inside');
        // Period inside opening times;
        if (closed) return startDate;
      } else if (
        startDate.isBefore(openingStart) &&
        endDate.isSameOrBefore(openingStart)
      ) {
        // console.log('   End inside');
        // Period ends inside opening times;
        if (closed) return openingStart;
        else return startDate;
      } else if (
        startDate.isSameOrBefore(openingEnd) &&
        endDate.isAfter(openingEnd)
      ) {
        // console.log('   Start inside');
        // Period starts inside opening times;
        if (closed) return startDate;
        else return openingEnd;
      }
    };
    if (periodsBetween.length) {
      const period = periodsBetween[0];
      return closureForOpening(
        start,
        end,
        period.startAt,
        period.endAt,
        period.closed
      );
    }

    const days =
      (moment(end).startOf("day").diff(moment(start).startOf("day"), "days") +
        1) %
      7;
    for (let day = 0; day < days; day += 1) {
      const dayStart =
        day === 0 ? start : moment(start).add(day, "days").startOf("day");

      const dayEnd =
        day === days - 1 ? end : moment(start).add(day, "days").endOf("day");

      // console.log(' DAY END: ', dayEnd.toISOString());
      const weekday = weekdays[(start.isoWeekday() + day - 1) % 7];
      const openingTime = openingTimes?.[weekday] || DEFAULT_WEEKDAY;

      const openingTimeStartAt = openingTime.startAt
        ? dateWithTime(dayStart, openingTime.startAt, timezone)
        : moment(dayStart).startOf("day");
      const openingTimeEndAt = openingTime.endAt
        ? dateWithTime(dayEnd, openingTime.endAt, timezone)
        : moment(dayEnd).endOf("day");

      const dayClosure = closureForOpening(
        dayStart,
        dayEnd,
        openingTimeStartAt,
        openingTimeEndAt,
        !!openingTime.closed
      );
      if (dayClosure) return dayClosure;
    }
  };

  // console.log(' Is open at start: ', spotIsOpen(spotData, startAt.toDate()));
  // console.log(' Is open at end: ', spotIsOpen(spotData, endAt.toDate()));
  // console.log(' Closure between: ', closureBetween(startAt, endAt));

  const closure = closureBetween(startAt, endAt);
  if (!closure) {
    return {
      open: true,
      startAt: startAt.toDate(),
      endAt: endAt.toDate(),
      code: "open",
    };
  } else if (closure.isSame(startAt)) {
    return {
      open: false,
      startAt: startAt.toDate(),
      endAt: startAt.toDate(),
      code: "start-closed",
    };
  } else if (!spotIsOpen(spotData, endAt.toDate())) {
    return {
      open: false,
      startAt: startAt.toDate(),
      endAt: closure.toDate(),
      code: "end-closed",
    };
  } else if (!parkWhenClosed) {
    return {
      open: false,
      startAt: startAt.toDate(),
      endAt: closure.toDate(),
      code: "between-closed",
    };
  } else {
    return {
      open: true,
      startAt: startAt.toDate(),
      endAt: endAt.toDate(),
      code: "open",
    };
  }
}
