import { VehicleData } from ".";
import Vehicle from "./vehicle";
import Store from "../..";
import { asyncAction } from "../../../helpers";
import Collection from "../../general/collection";

interface CreateData extends Omit<VehicleData, "userId" | "licenseRef" | "id"> {
  anonymous?: boolean;
}

class Vehicles extends Collection<Vehicle> {
  readonly userId?: string;
  constructor(userId: string | void, store: Store) {
    super(
      "vehicles",
      {
        createDocument: (source, options) =>
          new Vehicle(source, options, store),
        query: (ref) => ref.where("userId", "==", userId || ""),
      },
      store
    );
    this.userId = this.store.auth.user?.id;
  }

  create = asyncAction<CreateData, Vehicle>(async (data) => {
    const { anonymous, ...otherData } = data;
    const userId = this.store.auth.user?.id;
    if (!userId) throw new Error("Not authenticated");
    const vehicle = await this.add({
      ...otherData,
      ...(!anonymous ? { userId } : {}),
      licenseRef: sanitizeLicense(otherData.licensePlate || ""),
    });
    return new Vehicle(`vehicles/${vehicle.id}`, {}, this.store);
  });
}

export function sanitizeLicense(license: string) {
  return license.replace(/[-\s]/g, "").toLowerCase().trim();
}

export default Vehicles;
